import React from 'react';
import { graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import {
  CustomPageBySlugQuery,
  ContentfulCustomPageContent,
  ContentfulCustomPageContentContent,
  Maybe,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import ContentfulRichText from './contentfulRichText';
import { Header1, Header2 } from './headers';
import Layout from './layout';
import SEO from './seo';
import SideNavMenu from './menus/sideNavMenu';
import { MenuItems } from '../utils/hooks/sideNavMenu';
import Video from './video';

type CustomPageSection = Pick<
  ContentfulCustomPageContent,
  'id' | 'slug' | 'header'
> & {
  content?: Maybe<Pick<ContentfulCustomPageContentContent, 'raw'>>;
};

type SectionProps = {
  section: CustomPageSection;
};

const Section: React.FC<SectionProps> = ({ section }: SectionProps) => (
  <div className="side-nav-section -mt-8 pt-24" id={section.slug as string}>
    {section.header && (
      <Header2 color="black" mobileColorsFlipped className="mb-4 mx-5 md:mx-0">
        {section.header}
      </Header2>
    )}
    <div className="about-content text-base text-white md:text-black">
      <ContentfulRichText document={section.content} />
    </div>
  </div>
);

type Props = {
  pageContext: {
    header: string;
    slug: string;
  };
  data: CustomPageBySlugQuery;
};

const CustomPage: React.FC<Props> = ({ pageContext, data }: Props) => {
  const customMenu: MenuItems | undefined = data?.contentfulCustomPage?.sections
    ?.map((section) => {
      if (!section || !section.slug || !section.header) {
        return {
          id: 'none',
          text: 'none',
        };
      }
      return {
        id: section.slug,
        text: section.header,
      };
    })
    .filter(({ id }) => id !== 'none');

  const video = data?.contentfulCustomPage?.video;
  const photo = data?.contentfulCustomPage?.photo;
  return (
    <Layout pathname="TODO: add pathname">
      <SEO title={pageContext.header} />
      <div className="container mx-auto md:flex pt-16">
        <div className="w-1/4 hidden md:block">
          <div className="fixed flex container mx-auto z-10">
            <div className="w-1/4 pt-16 pr-16">
              {customMenu && (
                <SideNavMenu
                  pageSlug={pageContext.slug}
                  menuItems={customMenu}
                />
              )}
            </div>
            <div className="flex flex-1" />
          </div>
        </div>
        <div className="md:flex md:flex-1 z-20">
          <div className="md:max-w-screen-xs xl:max-w-screen-sm">
            {(video || photo) && (
              <Header1
                tag="h1"
                color="black"
                mobileColorsFlipped
                className="mx-5 md:mx-0 mb-10 uppercase"
              >
                {pageContext.header}
              </Header1>
            )}
            {video && photo && (
              <Video
                videoUrl={video}
                photo={photo}
                title={pageContext.header}
              />
            )}
            {!video && photo && (
              <div className="w-full">
                <GatsbyImage
                  alt="TODO: add alt tag"
                  style={{ width: '100%' }}
                  image={photo?.gatsbyImageData}
                />
              </div>
            )}
            {data?.contentfulCustomPage?.sections?.map((section) => {
              if (!section) {
                return null;
              }
              return <Section key={section.id} section={section} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CustomPageBySlug($slug: String!) {
    contentfulCustomPage(slug: { eq: $slug }) {
      photo {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      video
      sections {
        id
        header
        slug
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              title
              description
              gatsbyImageData(layout: CONSTRAINED)
            }
            ... on ContentfulVideo {
              contentful_id
              __typename
              embedUrl
              thumbnail {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomPage;
